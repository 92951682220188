import React from "react";
import PropTypes from "prop-types";
import SvgHomeIntroOne from "../../../../../assets/images/vector/components/common/mainPage-home_01.svg";
import OFFER_CONTENT from "./index.content";
import { getTrueClasses } from "../../../../../helpers";
import "./index.scss";

const Offer = ({ isLoading, isSecondStateCollectEmail, isPaid, isOpacity }) => {
  const { unauthorized, trial, paid } = OFFER_CONTENT;
  const { list, status, title } =
    !isLoading && isSecondStateCollectEmail
      ? isPaid
        ? paid
        : trial
      : unauthorized;

  return (
    <div
      className={getTrueClasses(
        "PgDMain-HI-O",
        status && `PgDMain-HI-O_${status}`,
        isOpacity && "PgDMain-HI-O-opacity"
      )}
    >
      <p className="PgDMain-HI-O-title">{title}</p>
      <ul className="PgDMain-HI-O-list">
        {list.map((text, i) => (
          <li key={i}>
            <img className="PgDMain-HI-O__img" src={SvgHomeIntroOne} alt="-" />
            {text}
          </li>
        ))}
      </ul>
    </div>
  );
};
Offer.propTypes = {
  isSecondStateCollectEmail: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPaid: PropTypes.bool,
};

export default Offer;
